import React from 'react';

const FooterBottom = () => {
  // Get the current year from Date.now()
  const currentYear = new Date(Date.now()).getFullYear();

  return (
    <div className="w-full py-10">
      <p className="text-center text-gray-500 text-base">
        © {currentYear}. All rights reserved by Entsoft LTD
      </p>
    </div>
  );
}

export default FooterBottom;
