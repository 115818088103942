import React from 'react'
// import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp, FaPhone, FaEnvelope } from "react-icons/fa";

// import { contactImg } from "../../assets/index";
import { contactImage } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        // src={contactImg}
        src={contactImage}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Innovate with Us</h3>
        <p className="text-lg font-normal text-gray-400">
        Dedicated Customer Support
        </p>
        <p className="text-base text-gray-400 tracking-wide">
        Connect with us for tailored technology solutions. Our team is ready to respond within 5 minutes to address your needs and explore the best options for your business.
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+234 8039456059</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">info@entsoft.com.ng</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Speak with Us</h2>


{/* 
        <div className="flex gap-4">
          <span className="bannerIcon">
            <FaFacebookF />
          </span>
          <span className="bannerIcon">
            <FaTwitter />
          </span>
          <span className="bannerIcon">
            <FaLinkedinIn />
          </span>
        </div>
       */}

        <div className="flex gap-4">
          <span className="bannerIcon ctaIcon">
            <a href="https://wa.me/+2348039456059" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
          </span>
          <span className="bannerIcon ctaIcon">
            <a href="tel:+2348039456059" target="_blank" rel="noopener noreferrer">
              <FaPhone />
            </a>
          </span>
          <span className="bannerIcon ctaIcon">
            <a href="mailto:Info@entsoft.com.ng" target="_blank" rel="noopener noreferrer">
              <FaEnvelope />
            </a>
          </span>
        </div>




      
      
      </div>
    </div>
  );
}

export default ContactLeft