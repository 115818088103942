
import React from 'react';
import Title from '../layouts/Title';
import { projectOne, projectTwo, projectThree, projectecommerce, projectsolar, projectoutlook, } from "../../assets/index";
import {ProjectsCard,ProjectsCard2} from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="HIGHLIGHT OF SOME OF OUR WORK"
          des="Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
      <ProjectsCard
          title="Mobile App "
          des="Developed a mobile app with real-time chat, push notifications, and social sharing, allowing users to stay connected and engaged with the brand on-the-go."
          src={projectTwo}
        />
       
        <ProjectsCard2
          title="E-Commerce Platform with Advanced Security"
          des="Built for a leading retail client, this platform includes encrypted transactions, data protection, and compliance-driven security features to ensure safe user experiences."
          src={projectecommerce}
        />
        <ProjectsCard
          title="Cloud Migration and Optimization"
          des="Implemented scalable cloud infrastructure for a growing business, enabling flexible storage, high availability, and optimized performance tailored to peak demands."
          src={projectOne}
        />
        <ProjectsCard
          title="Professional Email System Integration"
          des="Established secure, branded email solutions with features like anti-spam, mobile compatibility, and data retention for streamlined professional communication."
          src={projectoutlook}
        />
          <ProjectsCard
          title="Search-Optimized Website with Digital Marketing Strategy"
          des="A high-ranking website built with SEO best practices, backed by a digital marketing campaign targeting the client's niche market for maximum reach and engagement."
          src={projectThree}
        />
        <ProjectsCard
          title="Enterprise Resource Planning (ERP) System"
          des="Our custom ERP solution integrates finance, HR, and operations into a seamless platform, enhancing productivity and insight for client operations."
          src={projectsolar}
        />
     
      
      </div>
    </section>
  )
}

export default Projects;
