

// import { AiFillAppstore } from "react-icons/ai"; 
// import { FaMobile, FaGlobe, FaShieldAlt } from "react-icons/fa";
// import { SiProgress, SiAntdesign, SiCloudflare } from "react-icons/si";
// import { MdDeveloperMode, MdOutlineEngineering, MdOutlineBusinessCenter } from "react-icons/md";
// import { BiBullseye } from "react-icons/bi"; // Icon for Digital Marketing

// // Features Data
// export const featuresData = [
//   {
//     id: 1,
//     icon: <MdOutlineBusinessCenter />,
//     title: "Strategic Solutions",
//     des: "We transform complex ideas into actionable solutions that align with your business goals, leveraging technology to optimize and grow.",
//   },
//   {
//     id: 2,
//     icon: <MdOutlineEngineering />,
//     title: "Software Engineering",
//     des: "Our team builds high-performance software tailored to your unique needs, ensuring efficiency, scalability, and a competitive edge.",
//   },
//   {
//     id: 3,
//     icon: <FaShieldAlt />,
//     title: "Cybersecurity",
//     des: "We safeguard your operations with comprehensive cybersecurity, from threat detection and data protection to compliance and recovery solutions.",
//   },
//   {
//     id: 4,
//     icon: <AiFillAppstore />,
//     title: "Website Development",
//     des: "We create intuitive, high-impact applications that enhance user experiences and foster engagement across all digital platforms.",
//   },
//   {
//     id: 5,
//     icon: <SiCloudflare />,
//     title: "Cloud Computing",
//     des: "Our cloud solutions provide you with flexible, secure, and scalable infrastructure to fuel your growth and adapt to dynamic business demands.",
//   },

//   {
//     id: 6,
//     icon: <MdDeveloperMode />,
//     title: "Network Engineering",
//     des: "Our experts design reliable, scalable networks to keep your business seamlessly connected and ready for tomorrow’s challenges.",
//   },
//   {
//     id: 7,
//     icon: <FaMobile />,
//     title: "Mobile Development",
//     des: "Reach your audience wherever they are with mobile applications crafted for smooth performance and memorable user interactions.",
//   },
//   {
//     id: 8,
//     icon: <SiAntdesign />,
//     title: "UI/UX Design",
//     des: "We design user-centered interfaces that attract, engage, and deliver intuitive experiences, keeping your users coming back for more.",
//   },
//   {
//     id: 9,
//     icon: <FaGlobe />,
//     title: "Internet Services",
//     des: "Our comprehensive internet services cover everything from hosting and domain management to high-speed, secure connectivity solutions.",
//   },
//   {
//     id: 10,
//     icon: <SiProgress />,
//     title: "SEO Optimization",
//     des: "Let us boost your visibility and help you reach the right audience with effective SEO strategies that drive organic growth.",
//   },
//   {
//     id: 11,
//     icon: <BiBullseye />,
//     title: "Digital Marketing",
//     des: "Our digital marketing strategies are crafted to increase your reach, build brand authority, and drive conversions across digital platforms.",
//   },
// ];



import { AiFillAppstore } from "react-icons/ai"; 
import { FaMobile, FaGlobe, FaShieldAlt, FaEnvelope } from "react-icons/fa"; // Added FaEnvelope for Email Solutions
import { SiProgress, SiAntdesign, SiCloudflare } from "react-icons/si";
import { MdDeveloperMode, MdOutlineEngineering, MdOutlineBusinessCenter } from "react-icons/md";
import { BiBullseye } from "react-icons/bi"; // Icon for Digital Marketing

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <MdOutlineBusinessCenter />,
    title: "Strategic Solutions",
    des: "We transform complex ideas into actionable solutions that align with your business goals, leveraging technology to optimize and grow.",
  },
  {
    id: 2,
    icon: <MdOutlineEngineering />,
    title: "Software Engineering",
    des: "Our team builds high-performance software tailored to your unique needs, ensuring efficiency, scalability, and a competitive edge.",
  },
  {
    id: 3,
    icon: <FaShieldAlt />,
    title: "Cybersecurity",
    des: "We safeguard your operations with comprehensive cybersecurity, from threat detection and data protection to compliance and recovery solutions.",
  },
  {
    id: 4,
    icon: <FaEnvelope />,
    title: "Email Solutions",
    des: "We provide professional email setup, ensuring your team communicates with clients under a secure and branded email domain like info@entsoft.com.",
  },
  {
    id: 5,
    icon: <AiFillAppstore />,
    title: "Website Development",
    des: "We create intuitive, high-impact applications that enhance user experiences and foster engagement across all digital platforms.",
  },
  {
    id: 6,
    icon: <SiCloudflare />,
    title: "Cloud Computing",
    des: "Our cloud solutions provide you with flexible, secure, and scalable infrastructure to fuel your growth and adapt to dynamic business demands.",
  },
  {
    id: 7,
    icon: <MdDeveloperMode />,
    title: "Network Engineering",
    des: "Our experts design reliable, scalable networks to keep your business seamlessly connected and ready for tomorrow’s challenges.",
  },
  {
    id: 8,
    icon: <FaMobile />,
    title: "Mobile Development",
    des: "Reach your audience wherever they are with mobile applications crafted for smooth performance and memorable user interactions.",
  },
  {
    id: 9,
    icon: <SiAntdesign />,
    title: "UI/UX Design",
    des: "We design user-centered interfaces that attract, engage, and deliver intuitive experiences, keeping your users coming back for more.",
  },
  {
    id: 10,
    icon: <FaGlobe />,
    title: "Internet Services",
    des: "Our comprehensive internet services cover everything from hosting and domain management to high-speed, secure connectivity solutions.",
  },
  {
    id: 11,
    icon: <SiProgress />,
    title: "SEO Optimization",
    des: "Let us boost your visibility and help you reach the right audience with effective SEO strategies that drive organic growth.",
  },
  {
    id: 12,
    icon: <BiBullseye />,
    title: "Digital Marketing",
    des: "Our digital marketing strategies are crafted to increase your reach, build brand authority, and drive conversions across digital platforms.",
  },
];
