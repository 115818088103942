
import React from 'react';

const RightBanner = () => {
  return (
    <div className="w-full lgl:w-1/2 flex justify-center items-center ">
      <div className="relative w-[80%] h-[80%] rounded-lg overflow-hidden  ml-auto"> 
        <video
          width="80%"  /* Updated to fill the container */
          height="80%" /* Updated to fill the container */
          loop
          autoPlay
          muted
          className="rounded-lg"
          src="https://content.presentermedia.com/content/animsp/00018000/18469/businessmen_ideas_merging_500_nwm.mp4"
        ></video>
      </div>
    </div>
  );
};

export default RightBanner;
