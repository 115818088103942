import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from './Media';

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Software Engineering.", "Network Engineering.", "CyberSecurity.", "Cloud Computing.", "Mobile development." , "Internet services."],
      loop: true,
      typeSpeed: 200,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-8">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">HELLO!</h4>
        <h1 className="text-6xl font-bold text-white">
          We are <span className="text-designColor capitalize">Expert</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          in <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ffbd39"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Our team brings your ideas to life by combining expertise and years of experience across diverse fields, ensuring your business receives comprehensive, secure, and effective support at every stage. From design and development to seamless deployment, proactive management, security enhancements, and targeted digital marketing—trust us, we’ve got you covered.
        </p>
      </div>
     <Media />
    </div>
  );
}

export default LeftBanner